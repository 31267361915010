import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const About = (props) => {
  const markdown = props.data.services.edges;
  const areas = props.data.areas.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO
        title="Covid Update"
        description="air conditioning, furncace, and heater: tune-ups, installations, and repair. Emergency HVAC services available."
      />
      <div className="intro pb-4">
        <div className="container22 p-2">
          {/* <GatsbyImage className="background-image" image={ heroBackground.childImageSharp.gatsbyImageData } /> */}
          <h1>
            Need AC or Furnace:
            <br /> Repair, Service or Installation?{' '}
          </h1>
          <div className="cta-phone">
            <a href="tel: 952-435-3633">Call Today: (952) 435-3633</a>
          </div>
          <div className="cta-buttons">
            <ul>
              <li className="button orange-bg reverse-text">
                <Link to="/residential/furnace-repair/">Heating Services</Link>
              </li>
              <li className="button red-bg reverse-text">
                <Link to="/residential/furnace-repair/">Furnace Repair</Link>
              </li>
              <li className="button blue-bg reverse-text">
                <Link to="/residential/air-conditioning-repair-service">
                  Air Conditioning
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-8 pb-4">
        <p>
          The safety and well-being of our customers and our team is extremely
          important to us. We want you to know what we are doing to help keep
          you and our staff safe.
        </p>

        <p>
          Our employees are required to follow the CDC guidelines and are taking
          extra precautions to protect you and our technicians, such as:
        </p>
        <ul>
          <li>Maintaining safe distance at all times</li>
          <li>Sanitizing hands before and after every job</li>
          <li>Wearing PPE, such as face masks, if entering a home</li>
          <li>
            Providing a contact-less experience, so signatures and payments are
            collected electronically
          </li>
        </ul>
        <p>
          Even during this very trying time, we are here for you when you need
          us! Our team at LaSalle Heating and Air Conditioning, Inc. is proud of
          the comfort and value we provide our customers. Rest assured we are
          monitoring the situation daily. If any changes affect the way we serve
          you, we will let you know.
        </p>

        <p>
          Thank you again for letting us take care of your needs. Please stay
          healthy and safe.
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    areas: allMdx(
      filter: { fileAbsolutePath: { regex: "/areas/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          url
        }
      }
    }
  }
`;

export default About;
